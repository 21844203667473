<template>
    <div class="container">
        <section class="section banner-section">
            <VueSlickCarousel :arrows="true" :dots="true" class="slick-custom" v-bind="settings">
                <div v-for="(item,index) in banner" :key="index">
                    <router-link :to="item.link" class="touchable-link">
                        <img :src="item.img" alt="" class="object-fit-contain W-100">
                    </router-link>
                </div>
            </VueSlickCarousel>
        </section>
        <section class="section banner_bottom">
            <div class="d-flex justify-content-between gap-4">
                <div class="w-50">
                    <h3 class="small-title animated slow fadeIn fw-bold mb-3">LaunchPad</h3>
                    <div class="desc animated fadeInUp bg-white shadow p-3 d-flex">
                        <div class="ratio ratio-1x1 mb-3 flex-shrink-0"><img src="@/assets/img/banner2.jpg" alt="" class="rounded-lg object-fit-cover"></div>
                        <div class="d-flex flex-column justify-content-between txt_box">
                            <p class="mb-4">마그넷은 해외 유수 프로젝트들의 Seed Round에 투자를 하는 투자 회사로서 블록체인 기반의 분배 체계를 갖추어 투자자와 프로젝트사의 생태계를 구성하는 서비스입니다.</p>
                            <b-button class="bg-primary border-0 btn-sm" pill @click="$router.push('/launchpad')">자세히보기</b-button>
                        </div>
                    </div>
                </div>
                <div class="w-50">
                    <h3 class="small-title animated slow fadeIn fw-bold mb-3">Join Our Community</h3>
                    <div class="desc animated fadeInUp bg-white shadow p-3 d-flex">
                        <div class="ratio ratio-1x1 mb-3 flex-shrink-0"><img src="@/assets/img/banner2.jpg" alt="" class="rounded-lg object-fit-cover"></div>
                        <div class="d-flex flex-column justify-content-between txt_box">
                            <p class="mb-4">Join our vibrant community of game players and NFT enthusiasts and study all the latest tips and tricks.</p>
                            <div class="d-flex community-group-icon">
                                <a href="" target="_blank"><i class="fab fa-telegram"></i></a>
                                <a href="" target="_blank"><i class="fab fa-facebook"></i></a>
                                <a href="" target="_blank"><i class="fab fa-twitter"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section game-section animated fadeInUp delay-1s">
            <h3 class="small-title fw-bold mb-3">Games</h3>
            <ul class="d-flex gap-3">
                <li v-for="(item,index) in games" :key="index" class="shadow" @click="$router.push(`/game/${index}`)">
                    <div class="div ratio ratio-1x1"><img :src="item.img" alt="" class="object-fit-cover"></div>
                </li>
            </ul>
        </section>
        <section class="section auction-section animated fadeInUp delay-2s">
            <h3 class="small-title fw-bold mb-3 d-flex justify-content-between">Latest Auction <b-button pill variant="outline-primary" @click="$router.push('/nft')">View More</b-button></h3>
            <ul class="d-flex gap-3">
                <li v-for="(item,index) in latestAuction" :key="index" class="shadow d-flex flex-column p-3" @click="$router.push(`/nft/${index}`)">
                    <div class="d-flex flex-column">
                        <div class="ratio ratio-1x1"><img :src="item.img" alt="" class="rounded object-fit-cover"></div>
                        <b class="py-2">{{item.name}}</b>
                    </div>
                    <div class="d-flex flex-column justify-content-around">
                        <div class="d-flex justify-content-between align-items-center">
                            최신 가격
                            <span>{{item.price}} M</span>
                        </div>
                        <div class="d-flex justify-content-between align-items-center">
                            카운트 다운
                            <span>{{item.countdown}}</span>
                        </div>
                    </div>
                </li>
            </ul>
            <h3 class="small-title fw-bold pt-3 my-3 d-flex justify-content-between">Latest Sale <b-button pill variant="outline-primary" @click="$router.push('/nft')">View More</b-button></h3>
            <ul class="d-flex gap-3">
                <li v-for="(item,index) in latestAuction" :key="index" class="shadow d-flex flex-column p-3" @click="$router.push(`/nft/${index}`)">
                    <div class="d-flex flex-column">
                        <div class="ratio ratio-1x1"><img :src="item.img" alt="" class="rounded object-fit-cover"></div>
                        <b class="py-2">{{item.name}}</b>
                    </div>
                    <div class="d-flex flex-column justify-content-around">
                        <div class="d-flex justify-content-between align-items-center">
                            최신 가격
                            <span>{{item.price}} M</span>
                        </div>
                        <div class="d-flex justify-content-between align-items-center">
                            카운트 다운
                            <span>{{item.countdown}}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </section>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
    components: { VueSlickCarousel },
    data(){
        return{
            settings:{
                "arrows": false,
                "dots": true,
                "infinite": true,
                "slidesToShow": 1,
                "slidesToScroll": 1,
                "autoplay": true,
                "speed": 400,
                "autoplaySpeed": 4000,
                "cssEase": "linear"
            },
            banner:[
                {
                    link:"",
                    img: require("@/assets/img/banner.jpg")
                },
                {
                    link:"",
                    img: require("@/assets/img/banner.jpg")
                }
            ],
            games:[
                {
                    img: require('@/assets/img/banner2.jpg'),
                },
                {
                    img: require('@/assets/img/banner2.jpg'),
                },
                {
                    img: require('@/assets/img/banner2.jpg'),
                }
            ],
            latestAuction:[
                {
                    img: require('@/assets/img/banner2.jpg'),
                    name:'Comming Soon (#239)',
                    price:147,
                    countdown:'08:03:21'
                },
                {
                    img: require('@/assets/img/banner2.jpg'),
                    name:'Comming Soon (#240)',
                    price:147,
                    countdown:'08:03:21'
                },
                {
                    img: require('@/assets/img/banner2.jpg'),
                    name:'Comming Soon (#241)',
                    price:147,
                    countdown:'08:03:21'
                }
            ],
            latestSale:[
                {
                    img: require('@/assets/img/banner2.jpg'),
                    name:'Comming Soon (#239)',
                    price:147,
                },
                {
                    img: require('@/assets/img/banner2.jpg'),
                    name:'Comming Soon (#240)',
                    price:147,
                },
                {
                    img: require('@/assets/img/banner2.jpg'),
                    name:'Comming Soon (#241)',
                    price:147,
                }
            ]
        }
    },
    mounted(){
        window.addEventListener('load', this.animationFunc);
        window.addEventListener('scroll', this.animationFunc);
    },
    beforeDestroy(){
        window.removeEventListener('scroll', this.animationFunc)
    },
    methods:{
        animationFunc(){
            const saTriggerMargin = 200;
            const saElementList = document.querySelectorAll('.fn_animated');

            for (const element of saElementList) {
                if (!element.classList.contains('show')) {
                    if (window.innerHeight > element.getBoundingClientRect().top + saTriggerMargin) {
                    element.classList.add('show');
                    }
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.small-title{
    text-align: left;
}
    .fn_animated{
        opacity: 0;
        transition: opacity 3s;
    }
    .fn_animated.show{
        opacity: 1;
    }
    .section{
        margin-bottom: 100px;
        .desc{  
            border-radius: 16px;
            font-size: 12px;
            margin: 0 auto;
            max-width: 800px;
            text-align: left;
            >div{
                // padding: 0 20px;
            }
            .txt_box{
                padding: 0 1em;
            }
            .ratio{
                width: 168px;
            }
        }
    }
    .game-section, .auction-section{
        li{
            width: 33%;
            border-radius: 16px;
            img{
                width: 100%;
            }
        }
    }
    .game-section{
        img{
            // height: 270px;
        }
    }
    .auction-section{
        img{
            // height: 270px;
        }
    }
    .small-title{
        button{
            margin-top: 15px;
        }
    }
    
@media screen and (max-width: 992px){
   .section .desc{
        flex-wrap: wrap;
        img{
            margin:0 auto 20px;
        }
        >div{
            padding: initial;
        }
        .ratio{
            width: 100%;
        }
   } 
}
@media screen and (max-width:767px) {
    
    .section .desc{
        flex-wrap: initial;
        img{
            margin:initial;
            margin-bottom: 15px;
        }
    } 
    .w-50, .game-section li, .auction-section li{
        width: 100% !important;
    }
    section>.d-flex , .section .desc{
        flex-direction: column;
    }
    .section{
        margin-bottom: 40px;
    }
}

</style>
<style lang="scss">
.slick-dots{
    bottom: 25px;
}
.slick-prev{
    left: 25px !important;
    transform: translateX(-25px);
}
.slick-next{
    right: 25px !important;
    transform: translateX(25px);
}
.slick-dots li.slick-active button:before,.slick-dots li button:before{
    color: white;
}
.slick-custom{
    margin: 0 auto;
    .touchable-link{
        display: block;
        width: 100%;
        overflow:hidden;
        border-radius: 16px;
        // height: 427px;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}
.slick-arrow{
    z-index: 1;
    opacity: 0;
    transition: all .3s;
}
.slick-slider:hover .slick-arrow.slick-prev, .slick-slider:hover .slick-arrow.slick-next{
    opacity: 1;
    transform: translateX(0);
}
.slick-prev:before, .slick-next:before{
    font-family: 'Font Awesome 5 Pro';
}
.slick-next:before{
    content: '\f054';
}
.slick-prev:before{
    content: '\f053';
}

@media screen and (max-width:768px) {
    .slick-custom{
        .touchable-link{
            // height: 227px; 
        }
        .slick-dots{
            bottom: 10px;
        }
        .slick-prev{
            transform: translate(-25px -50%);
        }
        .slick-next{
            transform: translate(25px -50%);
        }
    }
}

</style>